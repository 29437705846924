import React, { useContext } from 'react';
import Layout from '../components/Layout';
import { StoreContext } from '../context/StoreContext';
import styled from 'styled-components';

const StyledContainer = styled('section')`
  margin: 0 auto;
  padding: 130px;
  width: 600px;
`;

const DebugPage = () => {
  const { addProductToCheckout, toggleCartOpen } = useContext(StoreContext);

  const addGhostProductToCart = async () => {
    const testProduct = 'gid://shopify/Product/8922952958169';
    await addProductToCheckout(testProduct, 1);
    toggleCartOpen();
  };

  return (
    <Layout noIndex={true}>
      <StyledContainer>
        <h1 style={{ marginBottom: 60 }}>Tools for debugging</h1>
        <button onClick={addGhostProductToCart}>
          Add test product to cart
        </button>
      </StyledContainer>
    </Layout>
  );
};

export default DebugPage;
